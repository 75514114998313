















































import Vue from 'vue';

export default Vue.extend({
  name: 'confirm-account',

  data: () => ({
    loading: false,
    message: '',
    messageType: '',
  }),

  methods: {
    back() {
      this.$router.push('/');
    },
    async resend() {
      this.loading = true;
      try {
        await this.$store.dispatch('auth/resendConfirmation');
        this.loading = false;
        this.message = 'Verification email was sent!';
        this.messageType = 'success';
      } catch (error) {
        console.log('error in CreateAccount - send');
        console.log(error);
        this.messageType = 'error';
        this.message = error.message;
        this.loading = false;
      }
    },
  },
});
